<template>
    <div class="result_boxes">
        <div class="result_graph_box">
            <h3>投票結果</h3>
            <div class="graph_table_box">

                <table>

                    <thead>
                        <tr>
                            <th v-for="(count, person) in electionCounts" :key="person">
                                <div class="bar" :style="{ height: `${count * 5}px` }"></div>
                            </th>
                        </tr>
                        <tr>
                            <th v-for="(count, person) in electionCounts" :key="person">{{ person }}</th>
                        </tr>
                    </thead>

                </table>
            </div>
        </div>
        <div class="count_down" v-if="countRest > -1">
            <h3>過半数まであと</h3>
            <h1>{{ countRest }}人</h1>
        </div>
        <div class="count_donw" v-else>
            <h3>過半数に到達しました！</h3>

        </div>
    </div>
</template>
<script>
import { projectFirestore } from "@/firebase/config";
import { onMounted, ref } from "vue";
export default {
    name: "ElectionGraph",
    setup() {

        const allEngineerSize = ref(0);
        const electedEngineerSize = ref(0);
        const baseLine = ref(0);
        const countRest = ref(0);

        const fetchEngineersAllData = async () => {
            try {
                const res = await projectFirestore.collection("engineers").get();
                allEngineerSize.value = res.docs.filter(doc => {
                    const status = doc.data().status;
                    return status !== "retire" && status !== "other";
                }).length;
                baseLine.value = allEngineerSize.value / 2 + 1;


            } catch (error) {
                console.log("error:", error.message)
            }
        }

        const fechedElectionDatas = ref([]);
        const uniqueElectedPersons = ref([]);
        const electionCounts = ref({});
        const fetchElectionData = async () => {
            try {
                const res = await projectFirestore.collection("election").get();
                fechedElectionDatas.value = res.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                electedEngineerSize.value = res.size;
                const electedPersons = fechedElectionDatas.value.map(data => data.elected_person);
                uniqueElectedPersons.value = [...new Set(electedPersons)]

                const counts = {};
                fechedElectionDatas.value.forEach(data => {
                    const person = data.elected_person;
                    if (counts[person]) {
                        counts[person]++;
                    } else {
                        counts[person] = 1;
                    }
                    electionCounts.value = counts;

                })
            } catch (error) {
                console.log("error:", error)
            }

        }



        onMounted(async () => {
            await fetchEngineersAllData();
            await fetchElectionData();


            countRest.value = baseLine.value - electedEngineerSize.value;

        })

        return {
            fechedElectionDatas,
            uniqueElectedPersons,
            electionCounts,
            baseLine,
            electedEngineerSize,
            countRest
        }


    }
}
</script>
<style>
table {
    width: 100%;
    border-collapse: collapse;

}

th {
    text-align: center;
    vertical-align: bottom;
    /* 棒グラフをヘッダーの上に表示 */
    position: relative;
}

.bar {
    width: 20px;
    margin: 0 auto;
    background-color: #4caf50;
    transition: height 0.3s ease;
}

.result_graph_box {
    width: 900px;
    height: 300px;
    background-color: white;
    border-radius: 10px;
    margin: 20px;
    padding: 20px;
    border: 2px solid #ddd;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

}

.graph_table_box {
    display: flex;
    height: 300px;
    align-items: flex-end;
    background-color: #eee;
    border-radius: 5px;

}

.result_graph_box h3 {
    margin: 0px;
}

.result_boxes {
    width: 100%;
    display: flex;
}

.count_down {
    width: 200px;
    height: 300px;
    border-radius: 5px;
    background-color: white;
    border: 2px solid #ddd;
    margin: 20px 20px 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>
