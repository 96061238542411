<template>
  <div class="data_whitebox">

    <div class="data_title_box">
      <div v-if="isElectedMembers">

        <h2>労働者代表選挙投票状況</h2>
      </div>
      <div v-else>
        <h2>未投票メンバーリスト</h2>
      </div>
      <div class="mitouhyou_button" @click="handleChengeElectedMembers">未投票メンバーリスト</div>



    </div>
    <div class="data_wrapper">

      <div class="data_innerbox" v-if="isElectedMembers">

        <table>
          <thead>
            <tr>
              <th>No.</th>
              <th>エリア</th>
              <th>担当者</th>
              <th>名前</th>
              <th>投票した人</th>
              <th>コメント</th>
              <th>投票日</th>

            </tr>
          </thead>
          <tbody>
            <tr v-for="(userdata, index) in fetchedElectionDatas" :key="index">
              <td class="table_no">{{ index + 1 }}</td>
              <td>
                <AreaStatus :id="userdata.user_id" :area="userdata.area" />
              </td>
              <td>
                <PICStatus :id="userdata.user_id" :pic="userdata.pic" />
              </td>
              <td class="user_name">
                <div>{{ userdata.last_name }}{{ userdata.first_name }}</div>
              </td>

              <td>
                {{ userdata.election[0].electedPerson }}
              </td>



              <td>
                {{ userdata.election[0].opinion }}
              </td>
              <td>{{ userdata.election[0].electedTime }}</td>

            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <table>
          <thead>
            <tr>
              <th>No.</th>
              <th>エリア</th>
              <th>担当者</th>
              <th>名前</th>


            </tr>
          </thead>
          <tbody>
            <tr v-for="(userdata, index) in engineersNotInElection" :key="index">
              <td class="table_no">{{ index + 1 }}</td>
              <td>
                <AreaStatus :id="userdata.user_id" :area="userdata.area" />
              </td>
              <td>
                <PICStatus :id="userdata.user_id" :pic="userdata.pic" />
              </td>
              <td class="user_name">
                <div>{{ userdata.last_name }}{{ userdata.first_name }}</div>
              </td>

            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import { ref, onMounted, watch, toRaw } from "vue";
import AreaStatus from "../home_comp/AreaStatus.vue";
import PICStatus from "../home_comp/PICStatus.vue";
import getDefaultDatas from "../composables/getDefaultDatas";


import { projectFirestore } from "@/firebase/config";


export default {
  props: {
    isSelect: Boolean,
    defaultdatas: {
      type: Array,
      default: () => [],
    },
    isFix: Boolean,
    reloadDefaultData: Function,
    now_page: Number,
  },
  name: "AllElectionData",
  components: {
    AreaStatus,
    PICStatus,

  },
  data() {
    return {
      itemsPerPage: 6,

      item_num: 1,
      selectedItems: [],
      isFilter: false,
      area_selected: null,
      pic_selected: null,
    };
  },


  setup(props) {
    const isElectedMembers = ref(true)

    const handleChengeElectedMembers = () => {
      isElectedMembers.value = !isElectedMembers.value
    }

    const currentPage = ref(1);
    const usersPayDatas = ref([]);


    const fetchedElectionDatas = ref([]);
    const { defaultdatas, datas_load } = getDefaultDatas("engineers");

    const engineersNotInElection = ref([]);




    const fetchElectionDatas = async () => {
      try {
        await datas_load();

        const combinedEngineers = {};

        const res = await projectFirestore.collection("election").get();
        res.docs.forEach((doc) => {
          const data = doc.data();
          const userId = data.userId
          if (combinedEngineers[userId]) {
            combinedEngineers[userId].election.push({
              electedPerson: data.elected_person,
              electedTime: formatTimestamp(data.elected_time),
              opinion: data.opinion,
            })
          } else {
            combinedEngineers[userId] = {
              user_id: userId,
              election: [{
                electedPerson: data.elected_person,
                electedTime: formatTimestamp(data.elected_time),
                opinion: data.opinion,
              }]
            }
          }

        });
        const result = Object.values(combinedEngineers);

        const userMap = {};
        defaultdatas.value.forEach(user => {
          userMap[user.company_id] = {
            first_name: user.first_name,
            last_name: user.last_name,
            pic: user.pic,
            status: user.status,
            area: user.area,
          }
        });

        fetchedElectionDatas.value = result.map(item => ({
          ...item,
          first_name: userMap[item.user_id]?.first_name || '',
          last_name: userMap[item.user_id]?.last_name || '',
          pic: userMap[item.user_id]?.pic || '',
          status: userMap[item.user_id]?.status || '',
          area: userMap[item.user_id]?.area || '',
        }))

        console.log("fetchdata", fetchedElectionDatas.value)
        const electionUserIds = fetchedElectionDatas.value.map(data => data.user_id);

        const filteredEngineers = defaultdatas.value.filter(
          data => {
            const userId = data.company_id;
            return !electionUserIds.includes(userId) && data.status !== "retire" && data.status !== "other";
          }
        )
        engineersNotInElection.value = filteredEngineers.map(item => toRaw(item))
        console.log("engineersNotInElection", engineersNotInElection.value)
      } catch (error) {
        console.error(error)
      }
    }



    const formatTimestamp = (Timestamp) => {
      const date = Timestamp.toDate();
      return date
        .toLocaleString("ja-JP", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })

    };

    const changePage = (pageNumber) => {
      currentPage.value = pageNumber;
    };
    console.log("props.page", props.now_page);
    if (props.now_page === 0) {
      changePage(1);
    } else {
      changePage(props.now_page);
    }
    if (props.isFix) {
      console.log(props.defaultdatas);
      watch(
        () => props.defaultdatas.value,
        (newDefaultDatas) => {
          if (newDefaultDatas !== null) {
            props.defaultdatas.value = newDefaultDatas;
          }
        }
      );

    }

    onMounted(async () => {
      await fetchElectionDatas();




    });
    return {
      currentPage,
      changePage,
      usersPayDatas,

      fetchedElectionDatas,
      isElectedMembers,
      handleChengeElectedMembers,
      engineersNotInElection


    }


  },

  methods: {

    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    engineerCheck(id) {
      if (this.selectedItems.length === 0) {
        this.selectedItems.push(id);
      } else {
        this.selectedItems.shift();
        this.selectedItems.push(id);
      }
      this.currentPageItems.forEach((defaultdata) => {
        if (!this.selectedItems.includes(defaultdata.id)) {
          defaultdata.isSelected = false;
        }
      });

      console.log(this.selectedItems);
      this.$emit("selected_engineer_id", this.selectedItems[0], this.currentPage);
    },
    handleFilter() {
      this.isFilter = !this.isFilter;
    },
    handleAreaChange() {
      this.$emit("area_selected", this.area_selected);
    },
    handlePICChange() {
      this.$emit("pic_selected", this.pic_selected);
    },
  },
};
</script>

<style scoped>
.data_title_box {
  width: 1042px;
  display: flex;
}

.data_title_box button {
  width: 102px;
  height: 44px;
  border-radius: 12px;
  border: 1px solid #cdcde3;
  color: #a8a8bd;
  line-height: 19.6px;
  word-wrap: break-word;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter .material-symbols-outlined {
  color: #a8a8bd;
  font-size: 20px;
  margin-left: 5px;
}

.data_whitebox {
  width: 1135px;
  height: 652px;
  border-radius: 16px;
  background: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin: 20px;
  box-sizing: border-box;

}

.data_wrapper {
  width: 100%;
  overflow-x: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

}

.data_innerbox {
  width: auto;


}

.table_no {
  text-align: center;
}


.user_name {
  width: 180px;
  white-space: nowrap;
}

.lecture {
  width: 100%;
}

.lecture_name {
  width: 500px;
  height: auto;
  overflow-x: auto;
  white-space: nowrap;
  font-size: 14px;
  display: flex;
  flex-direction: column;

}

.lecture_name div {
  display: inline-block;
}

.lecture_time {
  display: flex;
  width: 200px;
  font-size: 14px;
  align-items: center;
  gap: 10px;
}

.lecture_name span {
  font-weight: bold;
}

.lecture_time span {
  font-weight: bold;
}



table {
  /* will-change: 100%; */
  border-collapse: collapse;
}

.lecture table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}



.lecture_date {
  width: 100px;

}

.lecture td,
th {
  border: none;

}

td,
th {
  border: 1px solid #ccc;
  padding: 8px;
  white-space: nowrap;
}

.mitouhyou_button {
  width: 200px;
  height: 30px;
  font-size: 13px;
  font-weight: bold;
  background-color: cornflowerblue;
  border-radius: 5px;
  box-shadow: 2px 2px 1px #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
